import { useFormikContext } from 'formik';
import { FC, useState } from 'react';

import { FormControl, FormHelperText, InputAdornment, TextField } from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import { NumberParser } from 'src/utils/formattingUtils';

export const FormikNumberTextField: FC<any> = (props: any) => {
    const { currencySymbol, integer, form, field, ...otherProps } = props;
    const { name, value } = field;
    const { setFieldTouched, setFieldValue } = useFormikContext();

    const { user } = useAuth();
    const parsedValue = new NumberParser(user?.formattingLocale).parse(value);

    const [memoizedVal, setMemoizedVal] = useState(
        !Number.isNaN(+parsedValue) && value
            ? new Intl.NumberFormat(user?.formattingLocale).format(+parsedValue)
            : value,
    );

    const error = form.errors[field.name];
    const { 'data-testid': dataTest } = props;

    return (
        <FormControl fullWidth {...(dataTest ? { 'data-testid': dataTest } : {})}>
            <TextField
                fullWidth
                value={memoizedVal}
                {...otherProps}
                error={!!error}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>,
                }}
                onChange={(event) => {
                    let newParsedValue = new NumberParser(user?.formattingLocale).parse(
                        event.target.value,
                    );
                    let formValue = new Intl.NumberFormat(user?.formattingLocale).format(
                        +newParsedValue,
                    );
                    // eslint-disable-next-line
                    if (isNaN(newParsedValue)) {
                        return;
                    }
                    if (formValue === 'NaN') {
                        return;
                    }
                    if (!event.target.value) {
                        newParsedValue = null;
                        formValue = null;
                    }
                    setMemoizedVal(formValue);
                    setFieldTouched(name);
                    setFieldValue(name, newParsedValue);
                }}
            />
            {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        </FormControl>
    );
};
