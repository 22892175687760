import { ApaduaAPI } from './ApaduaAPI';
import { APIHeaders } from './HttpAPI';

export class ClientQuestionsLegacyAPI extends ApaduaAPI {
    resource: string = 'api/client-questions/';

    // eslint-disable-next-line
    getHeaders(): APIHeaders {
        const token = window.localStorage.getItem('jwtAccessToken');
        return {
            Authorization: `Bearer ${token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        };
    }

    create(payload): Promise<Array<any>> {
        const requestPayload = {
            data: {
                attributes: {
                    question: payload.question,
                    'user-company': payload.company,
                    'user-email': payload.email,
                    'user-full-name': payload.fullName,
                    'user-phone': payload.phoneNumber,
                },
                relationships: {
                    proposal: {
                        data: {
                            type: 'proposals',
                            id: payload.proposalID,
                        },
                    },
                },
                type: 'client-questions',
            },
        };

        return this.post('', requestPayload) as any;
    }
}
