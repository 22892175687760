import { matchRoutes, useLocation } from 'react-router-dom';

import routes from '../routes';

const useCurrentRoute = () => {
    const location = useLocation();
    const matchedRoutes = matchRoutes(routes, location);
    if (!matchedRoutes) {
        return null;
    }

    return matchedRoutes[matchedRoutes.length - 1].route;
};

export default useCurrentRoute;
