import { ApaduaAPI } from './ApaduaAPI';
import { APIHeaders } from './HttpAPI';

export class QuoteDocumentLegacyAPI extends ApaduaAPI {
    resource: string = 'api/quote-document-types/';

    // eslint-disable-next-line
    getHeaders(): APIHeaders {
        const token = window.localStorage.getItem('jwtAccessToken');
        return {
            Authorization: `Bearer ${token}`,
            Accept: 'application/vnd.api+json',
            'Content-Type': 'application/vnd.api+json',
        };
    }

    getDocumentTypes(): Promise<any> {
        return this.get('') as any;
    }
}
