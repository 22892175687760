/* eslint-disable */
import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useLingui } from '@lingui/react';
import ChatIcon from '@mui/icons-material/Chat';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import FeedIcon from '@mui/icons-material/Feed';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LaptopIcon from '@mui/icons-material/Laptop';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PollIcon from '@mui/icons-material/Poll';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box } from '@mui/material';

import NavSection from 'src/components/NavSection';
import { StepperTitle } from 'src/components/StepperTitle';
import { useProject } from 'src/project/state/hook';
import { ProposalStatus } from 'src/project/types';
import { useProposal } from 'src/proposal/state/hook';
import { toRelayId } from 'src/relay/utils';
import { appendAccessToken } from 'src/utils/appendAccessToken';

export const QuoteProposalSidebar: FC = () => {
    const { i18n } = useLingui();
    const location = useLocation();
    const proposal = useProposal();
    const project = useProject();

    const quoteProposalId = toRelayId('QuoteProposalNode', proposal?.id);
    const quoteProposalIntegerId = proposal?.id;

    const quoteId = toRelayId('QuoteNode', project?.id);
    const quoteIntegerId = project?.id;
    const projectName = project?.projectTitle;
    const sections = [
        {
            title: '',
            items: [
                {
                    title: i18n._('Summary'),
                    path: `/dashboard/rfp/${quoteId}/proposals/${quoteProposalId}/setup/summary`,
                    external: false,
                    icon: <FeedIcon fontSize="small" />,
                },
                {
                    title: i18n._('Questions & answers'),
                    path: `/dashboard/rfp/${quoteId}/proposals/${quoteProposalId}/setup/questions`,
                    icon: <ContactSupportIcon fontSize="small" />,
                },
                {
                    title: i18n._('Proposal'),
                    path: appendAccessToken(
                        `${window.APADUA_SOURCE}app/project/${quoteIntegerId}/proposal/${quoteProposalIntegerId}/proposal`,
                    ),
                    external: true,
                    icon: <TableChartIcon fontSize="small" />,
                },
                {
                    title: i18n._('Pitch presentation'),
                    path: `${window.APADUA_SOURCE}app/project/${quoteIntegerId}/proposal/${quoteProposalIntegerId}/beauty_contest`,
                    external: true,
                    icon: <LaptopIcon fontSize="small" />,
                },
                {
                    title: i18n._('Result'),
                    path: appendAccessToken(
                        `${window.APADUA_SOURCE}app/project/${quoteIntegerId}/proposal/${quoteProposalIntegerId}/result`,
                    ),
                    external: true,
                    icon: <PollIcon fontSize="small" />,
                },
                {
                    title: i18n._('User management'),
                    path: `/dashboard/rfp/${quoteId}/proposals/${quoteProposalId}/setup/users`,
                    icon: <ManageAccountsIcon fontSize="small" />,
                },
                {
                    title: i18n._('Announcements'),
                    path: `/dashboard/rfp/${quoteId}/proposals/${quoteProposalId}/setup/announcements`,
                    icon: <ChatIcon fontSize="small" />,
                },
                {
                    title: i18n._('Internal Documents'),
                    path: `/dashboard/rfp/${quoteId}/proposals/${quoteProposalId}/setup/documents`,
                    icon: <InsertDriveFileIcon fontSize="small" />,
                },
            ],
        },
    ];

    return (
        <>
            <Box sx={{ my: 2, px: 2, pb: 2 }}>
                {projectName ? (
                    <StepperTitle
                        title={projectName}
                        status={
                            proposal.status === ProposalStatus.onHold ? 'onHold' : proposal.status
                        }
                    />
                ) : null}
                <Box>
                    {sections.map((section) => (
                        <NavSection
                            key={section.title}
                            pathname={location.pathname}
                            sx={{
                                '& + &': {
                                    mt: 3,
                                },
                            }}
                            {...section}
                        />
                    ))}
                </Box>
            </Box>
        </>
    );
};
