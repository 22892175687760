import React from 'react';
import { GraphQLTaggedNode } from 'react-relay';
import { usePreloadedQuery, useQueryLoader } from 'react-relay/hooks';

import { useAppContext } from 'src/contexts/AppContext';
import { EMPTY_OBJECT } from 'src/utils/empty';

export function useGenericQuery<Data>(query: GraphQLTaggedNode, params: any = EMPTY_OBJECT): any {
    const { globalQueryRef } = useAppContext();
    const [queryReference, loadQuery, disposeQuery] = useQueryLoader(query, globalQueryRef);

    React.useEffect(() => {
        if (loadQuery) {
            loadQuery(params);
        }
        return () => {
            disposeQuery();
        };
    }, [loadQuery, params, disposeQuery]);

    const data = usePreloadedQuery<Data>(query, queryReference || null);

    return data;
}

/**
 * Maps a relay-query result to a list of items.
 * It also tried to replace id with entryId if it exists and adds a relayId field.
 * @param results
 */
export function mapEdgesToList(results) {
    const data = results?.edges?.map((edge) => edge.node) ?? [];
    return data.map((item) => ({
        ...item,
        id: item.entryId || item.id,
        relayId: item.id,
    }));
}
