export interface MemberResponse {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
}

export interface Member {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
}

export interface AnnouncementFileResponse {
    id: string;
    announcement: string;
    file: string;
    file_path: string;
}

export interface AnnouncementFile {
    id: string;
    announcement: string;
    file: string;
    filePath: string;
}

export interface UserLiteResponse {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
}

export interface UserLite {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface ProjectAnnouncementResponse {
    id: number;
    announcement_set: string;
    user: UserLiteResponse;
    content: string;
    created_at: string;
    announcement_files: AnnouncementFileResponse[];
    proposals: Array<{
        id: string;
        company_name: string;
    }>;
    notification_sent: boolean;
}

export interface ProjectAnnouncement {
    id: number;
    announcementSet: string;
    user: UserLite;
    content: string;
    createdAt: string;
    announcementFiles: AnnouncementFile[];
    proposals: Array<{
        id: string;
        companyName: string;
    }>;
    notificationSent: boolean;
}

export interface AnnouncementFilePayload {
    announcement: string;
    id: string;
}

export interface ProjectAnnouncementPayload {
    content: string;
    announcement_files: AnnouncementFilePayload[];
    proposals: number[];
}

export interface ProjectAnnouncementData {
    content: string;
    announcementFiles: AnnouncementFilePayload[];
    proposals: number[];
}

export interface AnnouncementProposal {
    id: string;
    companyName: string;
}

// must be kept in sync with src/app/listmanager/types.py
export enum ListTypeEnum {
    CHECKLIST = 'checklist',
    VENDORS_LIST = 'vendorslist',
}
