import { TitledItem } from 'src/projectBrief/types';
import { MemberResponse } from 'src/types';

import {
    CompanyInformation,
    CompanyInformationResponse,
    ProjectCoverLetterResponse,
} from './projectSummary/types';
import {
    Contact,
    ContactPayload,
    Individual,
    ProjectExtraVendor,
    ProjectExtraVendorPayload,
    ProjectExtraVendorResponse,
    ProjectRequestedVendor,
    ProjectVendor,
    ProjectVendorContactPayload,
    ProjectVendorContactResponse,
    ProjectVendorsFormData,
    ProjectVendorsPayload,
    ProjectVendorsResponse,
    RawContact,
    RawIndividual,
    RawProjectRequestedVendor,
    RawProjectVendor,
} from './projectVendors/types';
import {
    ProjectContactFormData,
    ProjectContactPayload,
    ProjectContactResponse,
    ProjectDetailsFormData,
    ProjectDetailsPayload,
    ProjectDetailsResponse,
} from './rfpDetails/types';

export type {
    ProjectContactResponse,
    ProjectContactPayload,
    ProjectContactFormData,
    ProjectDetailsResponse,
    ProjectDetailsPayload,
    ProjectDetailsFormData,
    ProjectVendorContactPayload,
    RawIndividual,
    RawContact,
    Individual,
    Contact,
    ContactPayload,
    RawProjectVendor,
    ProjectVendor,
    RawProjectRequestedVendor,
    ProjectRequestedVendor,
    ProjectVendorsResponse,
    ProjectVendorContactResponse,
    ProjectVendorsFormData,
    ProjectExtraVendorResponse,
    ProjectExtraVendorPayload,
    ProjectExtraVendor,
    ProjectVendorsPayload,
    CompanyInformationResponse,
    CompanyInformation,
    ProjectCoverLetterResponse,
};

export interface ProjectTimelineResponse {
    id: number;
    end_date: string;
    presentation_end_date: string;
    presentation_start_date: string;
    project_award_date: string;
    proposal_due_date: string;
    rfp_release_due_date: string;
    start_date: string;
    timeline_entries: Array<any>;
}

export interface ProjectRequest {
    firstName: string;
    lastName: string;
    email: string;
    projectRequestedVendors: ProjectRequestedVendor[];
    additionalInformation: string;
}

export interface ProjectTimelinePayload {
    end_date?: string;
    presentation_end_date?: string;
    presentation_start_date?: string;
    project_award_date?: string;
    proposal_due_date?: string;
    rfp_release_due_date?: string;
    start_date?: string;
    timeline_entries?: Array<any>;
}

export interface ProjectTimelineFormData {
    id: number;
    endDate: string;
    presentationEndDate: string;
    presentationStartDate: string;
    projectAwardDate: string;
    proposalDueDate: string;
    rfpReleaseDueDate: string;
    startDate: string;
    timelineEntries: Array<any>;
}

export interface ProjectInfoFormData {
    projectTitle: string;
    selectedServiceID: string;
    industryStandardID: string;
    internalDescription: string;
    initialSituation: string;
    internalOrganisationReference: string;
    engagementMode: string;
    projectApproach: string;
    projectObjectives: TitledItem[];
    projectDeliverables: TitledItem[];
    primaryProjectLocation?: number;
    secondaryProjectLocations?: Array<number>;
    budgetEstimate: number | null;
    budgetEstimateCurrency: number;
    budgetApprovalStatus: string | null;
}

/**
 * refer to {@link apadua.src.app.models.Quote}
 */
export enum ProjectStatus {
    requested = 'requested',
    draft = 'draft',
    sent = 'sent',
    onHold = 'on hold',
    cancelled = 'cancelled',
    completed = 'completed',
}

/**
 * refer to {@link apadua.src.app.models.Proposal}
 */
export enum ProposalStatus {
    draft = 'draft',
    invited = 'invited',
    accepted = 'accepted',
    submitted = 'submitted',
    lost = 'lost',
    dismissed = 'dismissed',
    awarded = 'awarded',
    onHold = 'on hold',
    cancelled = 'cancelled',
    declined = 'declined',
    inactive = 'inactive',
}

export interface QuoteProjectGoalPayload {
    name: string;
    description: string;
    id?: number;
}

export interface ProjectDeliverablePayload {
    name: string;
    description: string;
    id?: number;
}

export interface ProjectInfoPayload {
    internal_description: string;
    industry_standard: number;
    service_standard: number;
    organization_project_reference: string;
    quote_project_goals: Array<QuoteProjectGoalPayload>;
    project_deliverables: Array<ProjectDeliverablePayload>;
    main_location: number;
    secondary_locations: Array<number>;
    start_date: string;
    end_date: string;
    remote_consulting: string;
    initial_situation: string;
    project_approach: string;
    budget_estimate: number;
    budget_estimate_currency: number;
    budget_approval_status: string;
}

export interface CreateProjectPayload {
    id?: number;
    project_name: string;
    question_rounds: number;
    currency: string;
    fixed_fee: boolean;
    time_material: boolean;
}

export interface ProjectResponse {
    id: number;
    project_name: string;
    currency: number;
    question_rounds: number;
    status: string;
    project_details: ProjectDetailsResponse;
    project_vendors: ProjectVendorsResponse;
    project_info: ProjectInfoResponse;
    project_cover_letter: ProjectCoverLetterResponse;
    request: RequestResponse;
    company_information: CompanyInformationResponse;
    members: MemberResponse[];
    checklist: ChecklistResponse;
}

export interface ChecklistItemResponse {
    title: string;
    text: string;
    done: boolean;
    ai_generated: boolean;
    id: number;
}

export type NewChecklistItem = Omit<ChecklistItemResponse, 'id'>;

export interface ProjectChecklistItem {
    id?: number;
    title: string;
    text: string;
    done: boolean;
    aiGenerated: boolean;
}

export interface ChecklistResponse {
    id: number;
    name: string;
    note: string;
    locked: boolean;
    created: string;
    modified: string;
    parent: null | ChecklistResponse;
    list_items: [ChecklistItemResponse];
}

export interface QuoteProjectGoal {
    id: number;
    description: string;
    name: string;
    quote_id: string;
    project_goal_id: string;
}

export interface ProjectDeliverable {
    id: number;
    description: string;
    name: string;
    quote_id: string;
}

export interface RequestResponse {
    project_administrator_email?: string;
    person_first_name?: string;
    person_last_name?: string;
    project_requested_vendors?: RawProjectRequestedVendor[];
    additional_information?: string;
}

export interface ProjectInfoResponse {
    project_name: string;
    internal_description: string;
    project_approach: string;
    initial_situation: string;
    fixed_fee: boolean;
    time_material: boolean;
    start_date: string;
    end_date: string;
    question_rounds: number;
    currency: number;
    industry_standard: number;
    service_standard: number;
    quote_project_goals: QuoteProjectGoal[];
    project_deliverables: ProjectDeliverable[];
    main_location: number;
    secondary_locations: Array<number>;
    company_size_turnover: number | null;
    company_size_employees: number | null;
    remote_consulting: string;
    organization_project_reference: string;
    project_details: any;
    is_complete: boolean;
    budget_estimate: number;
    budget_estimate_currency: number;
    budget_approval_status: string;
}

// This enum must be in sync with `REMOTE_CONSULTING` which exists inside the Quote Model in
// src/app/models.py
enum EngagementMode {
    on_site = 'on_site',
    hybrid = 'hybrid',
    remote = 'remote',
}

enum StepStatus {
    warning = 'warning',
    success = 'success',
    error = 'error',
}

enum BudgetApprovalStatus {
    unknown = 'unknown',
    pending = 'pending',
    approved = 'approved',
}

export { EngagementMode, StepStatus, BudgetApprovalStatus };
