import axios from 'axios';

import { getAccessToken } from 'src/utils/login';

declare global {
    interface Window {
        APADUA_API: string;
        APADUA_SOURCE: string;
        ELASTICSEARCH_INDEX_NAME: string;
        CANONICAL_DOMAIN_SOURCE: string;
        CANONICAL_DOMAIN_NEXT: string;
        CANONICAL_DOMAIN_MONITOR: string;
        CANONICAL_DOMAIN_EXPLORE: string;
        CANONICAL_DOMAIN_APALYTICS: string;
        ENVIRONMENT: string;
        ENABLE_EXPLORE_2: boolean;
        ENABLE_PROJECT_BRIEFING: boolean;
        ENABLE_FIND_VENDORS: boolean;
        ENABLE_APALYTICS: boolean;
        ENABLE_CHECKLIST: boolean;
        TERMS_AND_CONDITIONS_URL: string;
        TERMS_AND_CONDITIONS_URL_DE: string;
        USES_NEW_WEBSITE: boolean;
        AZURE_TRANSLATOR_API: string;
        AZURE_TRANSLATOR_KEY: string;
        AZURE_TRANSLATOR_REGION: string;
    }
}
export const unathorizedApi = axios.create({
    responseEncoding: 'utf8',
    baseURL: window.APADUA_API,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

export const api = axios.create({
    responseEncoding: 'utf8',
    baseURL: window.APADUA_API,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use(async (c) => {
    const token = await getAccessToken();
    if (!token && c.url === '/graphql') {
        window.dispatchEvent(new Event('sessionExpired'));
        return null;
    }
    const configuration = c;
    configuration.headers = { Authorization: `Bearer ${token}` };
    return configuration;
});
