import React from 'react';
import {
    fetchQuery,
    usePreloadedQuery,
    useQueryLoader,
    useRelayEnvironment,
} from 'react-relay/hooks';

import { ConsultingCompanyAutocompleteConsultingCompanySearchQuery } from 'src/components/common/autocomplete/ConsultingCompanyAutocomplete';
import { useAppContext } from 'src/contexts/AppContext';

const useCompaniesRefetch = () => {
    const { globalQueryRef } = useAppContext();
    const [queryRef, loadQuery] = useQueryLoader(
        ConsultingCompanyAutocompleteConsultingCompanySearchQuery,
        globalQueryRef,
    );
    const environment = useRelayEnvironment();
    const [isRefreshing, setIsRefreshing] = React.useState(false);
    const refetch = React.useCallback(
        (value) => {
            if (value.length < 3) {
                return;
            }
            setIsRefreshing(true);
            fetchQuery(environment, ConsultingCompanyAutocompleteConsultingCompanySearchQuery, {
                text: value,
            }).subscribe({
                complete: () => {
                    setIsRefreshing(false);
                    loadQuery({ text: value }, { fetchPolicy: 'store-only' });
                },
                error: () => {
                    setIsRefreshing(false);
                },
            });
        },
        [queryRef, isRefreshing, environment, loadQuery],
    );

    const companies = (
        usePreloadedQuery(ConsultingCompanyAutocompleteConsultingCompanySearchQuery, queryRef)
            ?.consultingCompaniesSearch?.edges || []
    ).map((item) => item.node);

    return {
        refetch,
        companies,
    };
};

export default useCompaniesRefetch;
