import { t } from '@lingui/macro';

const useRfpStatus = (status: string) => {
    let tooltip = '';
    let label = '';

    switch (status) {
        case 'draft':
            tooltip = t`Draft`;
            label = t`Draft`;
            break;
        case 'sent':
            tooltip = t`Sent`;
            label = t`Sent`;
            break;
        case 'completed':
            tooltip = t`Completed`;
            label = t`Completed`;
            break;
        case 'onHold':
            tooltip = t`On hold`;
            label = t`On hold`;
            break;
        case 'cancelled':
            tooltip = t`Cancelled`;
            label = t`Cancelled`;
            break;
        case 'requested':
            tooltip = t`Requested`;
            label = t`Requested`;
            break;
        case 'rejected':
            tooltip = t`Rejected`;
            label = t`Rejected`;
            break;
        case 'invited':
            tooltip = t`Invited`;
            label = t`Invited`;
            break;
        case 'accepted':
            tooltip = t`Accepted`;
            label = t`Accepted`;
            break;
        case 'submitted':
            tooltip = t`Submitted`;
            label = t`Submitted`;
            break;
        case 'awarded':
            tooltip = t`Awarded`;
            label = t`Awarded`;
            break;
        case 'dismissed':
            tooltip = t`Dismissed`;
            label = t`Dismissed`;
            break;
        case 'lost':
            tooltip = t`Lost`;
            label = t`Lost`;
            break;
        case 'declined':
            tooltip = t`Declined`;
            label = t`Declined`;
            break;
        default:
            tooltip = '';
            label = '';
    }

    return { label, tooltip };
};

export default useRfpStatus;
