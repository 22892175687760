import HttpAPI, { APIHeaders } from './HttpAPI';

export class ApaduaAPI extends HttpAPI {
    resource: string = 'api/';

    constructor() {
        super(window.APADUA_API);
    }

    // eslint-disable-next-line
    getHeaders(): APIHeaders {
        const token = window.localStorage.getItem('jwtAccessToken');
        return {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
    }

    // eslint-disable-next-line
}
