import createSagaMiddleware from 'redux-saga';

import { configureStore } from '@reduxjs/toolkit';

import vendorsSliceReducer from 'src/explore/dashboard/state/reducer';
import loginSliceReducer from 'src/login/store/reducer';
import listsSliceReducer from 'src/pages/listManager/state/reducer';
import projectSliceReducer from 'src/project/state/reducer';
import projectBriefSliceReducer from 'src/projectBrief/state/reducer';
import proposalSliceReducer from 'src/proposal/state/reducer';
import ratecardProjectAnnouncementsSliceReducer from 'src/ratecardProject/state/reducer';
import rootSaga from 'src/sagas/root';
import searchSliceReducer from 'src/search/state/reducer';

import currenciesReducerSlice from '../currencies/reducer';
import apaduaAnnouncementsSliceReducer from './apaduaAnnouncements/reducer';
import companySizeEmployeesSliceReducer from './companySizeEmployees/reducer';
import companySizeTurnoverSliceReducer from './companySizeTurnover/reducer';
import countriesSliceReducer from './countries/reducer';
import documentTypesSliceReducer from './documentTypes/reducer';
import industryStandardsSliceReducer from './industryStandards/reducer';
import languagesSliceReducer from './languages/reducer';
import projectPhasesSliceReducer from './projectPhases/reducer';
import salutationsSliceReducer from './salutations/reducer';
import serviceStandardsSliceReducer from './serviceStandards/reducer';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        apaduaAnnouncements: apaduaAnnouncementsSliceReducer,
        lists: listsSliceReducer,
        vendors: vendorsSliceReducer,
        search: searchSliceReducer,
        countries: countriesSliceReducer,
        languages: languagesSliceReducer,
        salutations: salutationsSliceReducer,
        companySizeEmployees: companySizeEmployeesSliceReducer,
        companySizeTurnover: companySizeTurnoverSliceReducer,
        projectPhases: projectPhasesSliceReducer,
        projectBrief: projectBriefSliceReducer,
        project: projectSliceReducer,
        proposal: proposalSliceReducer,
        currencies: currenciesReducerSlice,
        documentTypes: documentTypesSliceReducer,
        login: loginSliceReducer,
        industryStandards: industryStandardsSliceReducer,
        serviceStandards: serviceStandardsSliceReducer,
        ratecardProjectAnnouncements: ratecardProjectAnnouncementsSliceReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;

// Infer the `GlobalState` and `AppDispatch` types from the store itself
export type GlobalState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
