import type { Router } from '@remix-run/router';

interface DependencyMap {
    router: Router;
}

class DependencyInjector {
    dependencies: DependencyMap;

    constructor() {
        this.dependencies = {
            router: null,
        };
    }

    get router() {
        return this.dependencies.router;
    }

    set router(router: Router) {
        this.dependencies.router = router;
    }
}

// Export a singleton for dependency injection
export default new DependencyInjector();
