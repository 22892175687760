import isEqual from 'lodash/isEqual';

import { createSelector } from '@reduxjs/toolkit';

import { currenciesSelector } from 'src/currencies/hook';
import { StepStatus } from 'src/project/types';
import { ProjectInfoDefaultvalue } from 'src/projectBrief/state/reducer';
import { companySizeEmployeesSelector } from 'src/redux/companySizeEmployees/hook';
import { companySizeTurnoverSelector } from 'src/redux/companySizeTurnover/hook';
import { countriesSelector } from 'src/redux/countries/hook';
import { projectPhasesSelector } from 'src/redux/projectPhases/hook';
import { salutationsSelector } from 'src/redux/salutations/hook';
import { GlobalState } from 'src/redux/store';

import { ProjectDetailsDefaultvalue } from './projectDetailsSlice';

export const projectSelector = (state: GlobalState) => state.project;
export const projectInfoSelector = (state: GlobalState) => projectSelector(state).projectInfo;
export const projectDetailsSelector = (state: GlobalState) => projectSelector(state).projectDetails;
export const projectTimelineSelector = (state: GlobalState) =>
    projectSelector(state).projectTimeline;

export const projectVendorsSelector = (state: GlobalState) => projectSelector(state).projectVendors;
export const projectAnnouncementsSelector = (state: GlobalState) =>
    projectSelector(state).projectAnnouncements;
export const projectCoverLetterSelector = (state: GlobalState) =>
    projectSelector(state).data.projectCoverLetter;

export const projectChecklistSelector = (state: GlobalState) =>
    projectSelector(state).projectChecklist.data;

export const projectChecklistLoadingSelector = (state: GlobalState) =>
    projectSelector(state).projectChecklist.generateLoading;

export const projectStatusSelector = (state: GlobalState) => projectSelector(state).status;

export const projectInfoStatusSelector = (state: GlobalState) => projectInfoSelector(state).status;
export const projectDetailsStatusSelector = (state: GlobalState) =>
    projectDetailsSelector(state).status;
export const projectVendorsStatusSelector = (state: GlobalState) =>
    projectVendorsSelector(state).status;

export const contactInformationUserSelector = (state: GlobalState) =>
    projectVendorsSelector(state).contactInformationUser;

export const projectInfoLoadingSelector = (state: GlobalState) =>
    projectInfoSelector(state).loading;

export const projectTimelineLoadingSelector = (state: GlobalState) =>
    projectTimelineSelector(state).loading;
export const projectDetailsLoadingSelector = (state: GlobalState) =>
    projectDetailsSelector(state).loading;

export const projectVendorsLoadingSelector = (state: GlobalState) =>
    projectVendorsSelector(state).loading;

export const projectLoadingSelector = createSelector(
    projectInfoLoadingSelector,
    projectTimelineLoadingSelector,
    projectDetailsLoadingSelector,
    projectVendorsLoadingSelector,
    (projectInfoLoading, timelineLoading, detailsLoading, vendorsLoading) =>
        projectInfoLoading || timelineLoading || detailsLoading || vendorsLoading,
);

export const projectStepSelector = (state: GlobalState) => projectSelector(state).step;

export const selectProjectInfoStepStatus = createSelector(
    projectInfoSelector,
    projectSelector,
    (projectInfo, project) => {
        if (!projectInfo.data || !project.data) return StepStatus.warning;
        if (projectInfo.data.isComplete) return StepStatus.success;
        const equal = isEqual(
            { ...projectInfo.data, projectTitle: project.data?.projectTitle },
            ProjectInfoDefaultvalue,
        );
        return equal ? StepStatus.warning : StepStatus.error;
    },
);

export const selectProjectVendorsStepStatus = createSelector(
    projectVendorsSelector,
    (projectVendors) => {
        if (projectVendors.data.companies?.length === 0) return StepStatus.warning;

        return projectVendors.data.isComplete ? StepStatus.success : StepStatus.error;
    },
);

export const selectProjectDetailsStepStatus = createSelector(
    projectDetailsSelector,
    currenciesSelector,
    (projectDetails, currencies) => {
        if (!projectDetails.data) return StepStatus.warning;
        if (projectDetails.data.isComplete) return StepStatus.success;
        const euroCurrency = currencies.data.find((item) => item.isoCurrencyCode === 'EUR');
        const defaultState = { ...ProjectDetailsDefaultvalue, currency: euroCurrency.rawID };
        const currentState = { ...defaultState, ...projectDetails.data };

        const equal = isEqual(currentState, defaultState);
        return equal ? StepStatus.warning : StepStatus.error;
    },
);

export const selectProjectCoverLetterStatus = createSelector(
    projectCoverLetterSelector,
    (projectCoverLetter) =>
        projectCoverLetter?.isComplete ? StepStatus.success : StepStatus.warning,
);

export const selectProjectInfo = (state: GlobalState) => projectInfoSelector(state).data;

export const selectCompanyInformation = createSelector(
    (state: GlobalState) => companySizeEmployeesSelector(state).data,
    (state: GlobalState) => companySizeTurnoverSelector(state).data,
    (state: GlobalState) => countriesSelector(state).data,
    (state: GlobalState) => projectSelector(state).data,
    (companySizeEmployees, companySizeTurnover, countries, project) => {
        const companyData = { ...project.companyInformation };
        companyData.country = countries.find(
            (country) => country.id === project.companyInformation?.country,
        );
        companyData.employees = companySizeEmployees.find(
            (companySize) => companySize.id === project.companyInformation?.employees,
        );
        companyData.turnover = companySizeTurnover.find(
            (companyTurnover) => companyTurnover.id === project.companyInformation?.turnover,
        );

        return companyData;
    },
);

export const selectFullProjectInfo = createSelector(
    (state: GlobalState) => projectInfoSelector(state).data,
    (state: GlobalState) => projectTimelineSelector(state).data,
    (projectInfo, projectTimeline) => ({
        ...projectInfo,
        startDate: projectTimeline.startDate,
        endDate: projectTimeline.endDate,
    }),
);

export const selectFullProject = createSelector(
    (state: GlobalState) => projectSelector(state).data,
    selectFullProjectInfo,
    (state: GlobalState) => projectVendorsSelector(state).data,
    (state: GlobalState) => projectAnnouncementsSelector(state).data,
    (state: GlobalState) => projectDetailsSelector(state).data,
    (project, info, vendors, announcements, details) => ({
        ...project,
        info,
        vendors,
        announcements,
        details,
    }),
);

export const selectProjectDetails = createSelector(
    (state: GlobalState) => salutationsSelector(state).data,
    (state: GlobalState) => projectPhasesSelector(state).data,
    (state: GlobalState) => projectDetailsSelector(state).data,
    (state: GlobalState) => projectTimelineSelector(state).data,
    (salutations, projectPhases, details, projectTimeline) => {
        const newDetails = { ...details, ...projectTimeline };

        newDetails.projectPhases = projectPhases.map((projectPhase) =>
            details.projectPhases?.map((ph) => ph.id)?.includes(projectPhase.id)
                ? { ...projectPhase, selected: true }
                : { ...projectPhase, selected: false },
        );
        if (!newDetails.quoteContacts?.length) {
            newDetails.quoteContacts = [
                {
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    salutation: salutations[0]?.id,
                },
            ];
        }

        return newDetails;
    },
);
